import React from 'react'

import { FormSubGroup, Label } from '../../layout'
import { SelectContainer, useTowingJobFilterContext } from '../TowingJobFilter'

import { FilterSelect } from '../components/FilterSelect'
import { UserRole } from '../../../common/types'

type VisibilityFilter = 'open' | 'own' | 'others'

interface Option {
  label: string
  value: VisibilityFilter
}
interface VisibilityFilterProps {
  options?: Option[]
}

const visibilityOptions: { label: string; value: VisibilityFilter }[] = [
  { label: 'Avoimet', value: 'open' },
  { label: 'Omat', value: 'own' },
  { label: 'Muiden', value: 'others' },
]

const limitedVisibilityOptions: { label: string; value: VisibilityFilter }[] = [
  { label: 'Avoimet', value: 'open' },
  { label: 'Omat', value: 'own' },
]

export const VisibilityFilter = ({ options }: VisibilityFilterProps) => {
  const { values, setValues, currentUser } = useTowingJobFilterContext()

  const showAllOptions = currentUser.role === UserRole.main_user || currentUser.role === UserRole.duty_officer
  const optionsValues = options ? options : showAllOptions ? visibilityOptions : limitedVisibilityOptions

  const handleVisibilityChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions ? selectedOptions.map((opt: any) => opt.value) : []

    setValues({
      ...values,
      filters: {
        ...values.filters,
        visibility: selectedValues,
      },
    })
  }

  return (
    <FormSubGroup>
      <Label>Töiden näkyvyys</Label>
      <SelectContainer>
        <FilterSelect
          isMulti
          placeholder="Valitse..."
          options={optionsValues.map(option => ({ value: option.value, label: option.label }))}
          value={optionsValues
            .filter(option => values.filters.visibility.includes(option.value))
            .map(option => ({ value: option.value, label: option.label }))}
          onChange={handleVisibilityChange}
        />
      </SelectContainer>
    </FormSubGroup>
  )
}
