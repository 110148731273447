import React from 'react'
import styled, { css } from 'styled-components'

export const FilterCheckBox = ({
  disabled,
  checked,
  label,
  onChange,
}: {
  disabled?: boolean
  checked: boolean
  label: string
  onChange: (checked: boolean) => void
}) => {
  return (
    <CheckboxContainer style={{ whiteSpace: 'nowrap' }} className="checkbox-container">
      <Checkbox
        disabled={disabled}
        checked={checked}
        type="checkbox"
        id="openAll"
        onChange={e => onChange(e.target.checked)}
      />
      <CheckboxLabel color={disabled ? 'grey400' : ''}>{label}</CheckboxLabel>
    </CheckboxContainer>
  )
}

const Checkbox = styled.input<{ disabled?: boolean }>`
  appearance: none;
  margin: 0;
  padding: 0;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: ${props => props.theme.radius.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  &:checked:after {
    content: '\\2714';
    font-size: 1.25rem;
    color: ${props => props.theme.colors.black};
  }
  &:disabled {
    background-color: ${props => props.theme.colors.grey200}; // Optional: Change background color for disabled state
    border-color: ${props => props.theme.colors.grey400}; // Optional: Lighter border for disabled state
  }
`
const CheckboxContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
`

const CheckboxLabel = styled.div`
  margin: 0 0 0 1rem;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
`
