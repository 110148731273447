import React from "react"
import { FormSubGroup, Label } from '../../layout'

import { MultiValue, Option } from '../components/MultiValueSelect'
import { FilterSelect } from '../components/FilterSelect'
import { SelectContainer, useTowingJobFilterContext } from '../TowingJobFilter'

interface OptionType {
  label: string
  value: string
}

interface JobTypeOptionsProps {
  options: OptionType[]
}

export const JobTypeFilter = ({ options }: JobTypeOptionsProps) => {
  const { values, setValues } = useTowingJobFilterContext()

  const handleLabelChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions ? selectedOptions.map((opt: any) => opt.value) : []

    setValues({
      ...values,
      filters: {
        ...values.filters,
        labels: selectedValues,
      },
    })
  }

  return (
    <FormSubGroup>
      <Label>Työn tyyppi</Label>
      <SelectContainer>
        <FilterSelect
          isMulti
          placeholder="Valitse..."
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={options.map(option => ({ value: option.value, label: option.label }))}
          value={options
            .filter(option => values.filters.labels.includes(option.value))
            .map(option => ({ value: option.value, label: option.label }))}
          onChange={handleLabelChange}
          components={{ MultiValue, Option }}
          maxToShow={3}
        />
      </SelectContainer>
    </FormSubGroup>
  )
}