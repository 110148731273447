import gql from 'graphql-tag'

import { TowingOrderJobInterface } from '../../components/TowingOrderRow/types'
import { TowingRecordRowInterface } from '../../components/TowingRecordRow/types'

export const TOWING_JOBS_OPEN_QUERY = gql`
  query TowingOrders($status: TowingJobQueryStatus, $filters: TowingJobFilters) {
    towingOrders(status: $status, filters: $filters) {
      __typename
      ... on GetTowingOrdersSuccess {
        towingOrders {
          id
          visibility
          status
          type
          typeIdentifier
          stationId
          label
          operatorId
          locked
          vehicleDetails {
            registrationNumber
            vehicleClass
            makeAndModel
          }
          jobDetails {
            serviceType
            towingDate
            towingDateType
            towingEndDate
            towingTime
            towingEndTime
            towingReason
            additionalInfo
            notes
          }
          from {
            address
            city
            description
          }
          to {
            address
            city
            description
          }
          automobileAndTouringClubFinland{
            repairShopId
          }
        }
      }
    }
    towingRecords(status: $status, filters: $filters) {
      __typename
      ... on GetTowingRecordsSuccess {
        towingRecords {
          id
          type
          typeIdentifier
          status
          jobStatus
          label
          stationId
          operatorId
          locked
          vehicleDetails {
            registrationNumber
            makeAndModel
          }
          jobDetails {
            serviceType
            towingDate
            towingReason
            additionalInfo
            notes
          }
          routes {
            type
            ordering
            address
            city
            description
          }
          sosServiceOrder {
            orderStatus
          }
          automobileAndTouringClubFinland{
            repairShopId
          }
        }
      }
    }
  }
`

export const TOWING_JOBS_COMPLETED_QUERY = gql`
  query TowingOrders($filters: TowingJobFilters) {
    towingRecords(status: COMPLETED, filters: $filters) {
      __typename
      ... on GetTowingRecordsSuccess {
        towingRecords {
          id
          operatorId
          recordNumber
          type
          typeIdentifier
          status
          jobStatus
          locked
          vehicleDetails {
            registrationNumber
          }
          jobDetails {
            serviceType
            towingDate
            towingReason
            orderReference
            notes
            additionalInfo
          }
          routes {
            type
            ordering
            address
            city
            description
          }
        }
      }
    }
  }
`

export const TOWING_JOBS_SEARCH_QUERY = gql`
  query TowingOrders($status: TowingJobQueryStatus, $filters: TowingJobFilters, $search: String) {
    towingOrders(status: $status, filters: $filters, search: $search) {
      __typename
      ... on GetTowingOrdersSuccess {
        towingOrders {
          id
          visibility
          status
          type
          typeIdentifier
          stationId
          label
          operatorId
          locked
          tags
          priorityTag
          vehicleDetails {
            registrationNumber
            vehicleClass
            makeAndModel
          }
          jobDetails {
            serviceType
            towingDate
            towingDateType
            towingEndDate
            towingTime
            towingEndTime
            towingReason
            additionalInfo
            notes
          }
          from {
            address
            city
            zipcode
            description
          }
          to {
            address
            city
            zipcode
            description
          }
          automobileAndTouringClubFinland{
            repairShopId
          }
        }
      }
    }
    towingRecords(status: $status, filters: $filters, search: $search) {
      __typename
      ... on GetTowingRecordsSuccess {
        towingRecords {
          id
          type
          typeIdentifier
          recordNumber
          status
          jobStatus
          label
          stationId
          operatorId
          locked
          tags
          vehicleDetails {
            registrationNumber
            makeAndModel
          }
          jobDetails {
            serviceType
            towingDate
            towingReason
            additionalInfo
            notes
          }
          routes {
            type
            ordering
            address
            zipcode
            city
            description
          }
          sosServiceOrder {
            orderStatus
          }
          automobileAndTouringClubFinland{
            repairShopId
          }
        }
      }
    }
  }
`

export interface TowingJobsResponse {
  towingOrders: TowingOrdersResult
  towingRecords: TowingRecordssResult
}

interface TowingOrdersResult
  extends Readonly<{
    __typename: string
    towingOrders: TowingOrderJobInterface[]
  }> {}

interface TowingRecordssResult
  extends Readonly<{
    __typename: string
    towingRecords: TowingRecordRowInterface[]
  }> {}

export const SUBSCRIBE_NOTIFICATION = gql`
  mutation SubscribeNotification($input: NotificationSubsciptionObject!) {
    subscribeNotification(input: $input) {
      __typename
      ... on SubscribeNotificationSuccess {
        id
      }
    }
  }
`
