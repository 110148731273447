import React from 'react'
import { FormSubGroup, Label } from '../../layout'
import { FilterSelect } from '../components/FilterSelect'
import { SelectContainer, TowingJobStatus, useTowingJobFilterContext } from '../TowingJobFilter'

interface StatusOption {
  label: string
  value: TowingJobStatus
}
interface StatusFilterProps {
  options?: StatusOption[]
}

const statusOptions: StatusOption[] = [
  { label: 'Kaikki', value: 'ALL' },
  { label: 'Valmiit', value: 'COMPLETED' },
  { label: 'Avoimet', value: 'OPEN' },
]

export const StatusFilter = ({ options }: StatusFilterProps) => {
  const { values, setValues } = useTowingJobFilterContext()
  const optionsValues = options ? options : statusOptions

  const handleStatusChange = (e: any) => {
    const selectedValue = e.value

    setValues({
      ...values,
      status: selectedValue,
    })
  }

  return (
    <FormSubGroup>
      <Label htmlFor="filter-select">Työn tila</Label>
      <SelectContainer>
        <FilterSelect
          options={optionsValues}
          value={optionsValues
            .filter(option => values.status === option.value)
            .map(option => ({ value: option.value, label: option.label }))}
          onChange={handleStatusChange}
        />
      </SelectContainer>
    </FormSubGroup>
  )
}
