import styled, { css } from 'styled-components'
import React from 'react'

interface Props extends React.HTMLProps<HTMLInputElement> {
  expanded?: boolean
}
const StyledSearchInput = styled.input<Props>`
  border: none;
  outline: none;
  padding: 5px;
  font-size: 1em;
  flex-grow: 1;
  width: ${props => (props.expanded ? '100%' : '0px')};
  transition: width 0.3s ease-in-out;

  ${props =>
    !props.expanded &&
    css`
      width: 0px;
      opacity: 0;
    `}
`

interface SearchInputProps {
  type?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  expanded?: boolean
  onBlur?: () => void
  onFocus?: () => void
}

export const SearchInput = (props: SearchInputProps) => {
  return <StyledSearchInput {...props} />
}
