import { useQuery } from '@apollo/react-hooks'
import React, { useState, useContext, useEffect, useCallback } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { ButtonColumn, ButtonRow, Column, Heading3, Page, Row, FlexContainer } from '../../../components/layout'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { useHistoryPush } from '../../../components/router'
import { TowingOrderRow } from '../../../components/TowingOrderRow'
import { TowingRecordRow } from '../../../components/TowingRecordRow'
import { TOWING_JOBS_OPEN_QUERY, TowingJobsResponse } from '../TowingJobsQuery'
import { useTowingJobsListChange } from '../towingJobsWatcher'
import Link from '../../../components/Link'
import styled from 'styled-components'
import { StateStore, DispatchStore } from '../../StoreProvider'
import { countJobs } from '../../StoreProvider/OpenJobCountWatcher'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { messages } from '../messages'
import { getStationByIdFromSettings } from '../../Parameters/station'
import { NoTowingJobs } from '../NoTowingJobs'
import { setOpenJobsCount } from '../../StoreProvider/actions'
import { getJobViewFilters } from '../../../components/JobViewFilters/jobViewFilters'
import { GetState } from '../../Parameters/preferences'
import { getOperatorLabelFromId } from '../../Parameters/user'

const HeaderContainer = styled(FlexContainer)`
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    justify-content: space-between;
  }
`

const AddButton = styled(Button)`
  max-width: 100%;
`

const CompletedJobsLink = styled(Link)`
  margin-left: 2rem;
  font-size: 1.2rem;
`

const OpenTowingJobsPage: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const [expandedRowId, clickOnRow] = useState<number | null>(null)
  const handleItemClick = (clickedId: number) => {
    clickOnRow(clickedId !== expandedRowId ? clickedId : null)
  }

  const TypedGenericResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingJobsResponse>
  const historyPush = useHistoryPush()

  const { formatMessage } = intl
  const translateMessage = (messageId: string) => formatMessage({ id: messageId })
  const { state } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)

  const filters = getJobViewFilters(GetState.filters(state))

  const towingJobsQueryResult = useQuery<TowingJobsResponse>(TOWING_JOBS_OPEN_QUERY, {
    variables: {
      filters,
      status: 'OPEN',
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const callback = useCallback(()=> {
    // tslint:disable-next-line: max-line-length
    // timeout because Innohinaus backend seems to send socket notification before new order is available through REST API
    setTimeout(() => {
      towingJobsQueryResult.refetch()
    }, 5000)
  },[towingJobsQueryResult])

  useTowingJobsListChange(callback)

  useEffect(() => {
    if (towingJobsQueryResult.data && towingJobsQueryResult.data.towingOrders.towingOrders) {
      const openAndUnassigned = countJobs(towingJobsQueryResult.data.towingOrders.towingOrders)
      dispatch(setOpenJobsCount(openAndUnassigned))
    }
  }, [towingJobsQueryResult.data, dispatch])

  return (
    <TypedGenericResultHandler queryResult={towingJobsQueryResult} loading unauthorized>
      {data => {
        return (
          <Page noPadding>
            <Row space="double">
              <Column>
                <HeaderContainer>
                  <Heading3>Työlista</Heading3>
                  <CompletedJobsLink to="/towing-jobs/completed">Valmiit työt</CompletedJobsLink>
                </HeaderContainer>
              </Column>
              <Column>
                <ButtonRow padding="0">
                  <ButtonColumn>
                    <AddButton
                      category="new"
                      label={formatMessage(messages.newTowingOrderButton)}
                      onClick={() => {
                        historyPush('/towing-order/add')
                      }}
                    />
                  </ButtonColumn>
                  {state && state.settings && state.settings.modules && state.settings.modules.sos && (
                    <ButtonColumn>
                      <AddButton
                        category="new"
                        label="+ Uusi SOS"
                        onClick={() => {
                          historyPush(`/towing-order/add/sos`)
                        }}
                      />
                    </ButtonColumn>
                  )}
                </ButtonRow>
              </Column>
            </Row>

            {(!data ||
              (data.towingOrders.towingOrders.length === 0 && data.towingRecords.towingRecords.length === 0)) && (
              <NoTowingJobs />
            )}

            {data &&
              data.towingOrders.towingOrders.map(towingOrder => (
                <TowingOrderRow
                  expandedRow={false}
                  handleItemClick={handleItemClick}
                  towingOrder={towingOrder}
                  translateMessage={translateMessage}
                  key={towingOrder.id}
                  slug={`/towing-order/${towingOrder.id}`}
                  station={getStationByIdFromSettings(towingOrder.stationId, state.settings)}
                  operatorName={getOperatorLabelFromId(
                    towingOrder.operatorId,
                    state.currentUser,
                    state.settings ? state.settings.operators : null
                  )}
                />
              ))}

            {data &&
              data.towingRecords.towingRecords.map(towingRecord => (
                <TowingRecordRow
                  expandedRow={false}
                  handleItemClick={handleItemClick}
                  towingRecord={towingRecord}
                  translateMessage={translateMessage}
                  key={towingRecord.id}
                  slug={`/towing-record/${towingRecord.id}`}
                  station={getStationByIdFromSettings(towingRecord.stationId, state.settings)}
                  operatorName={getOperatorLabelFromId(
                    towingRecord.operatorId,
                    state.currentUser,
                    state.settings ? state.settings.operators : null
                  )}
                />
              ))}
          </Page>
        )
      }}
    </TypedGenericResultHandler>
  )
}

export default injectIntl(OpenTowingJobsPage)
