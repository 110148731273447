import React, { useContext } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { TowingOrderFilters } from '../../../functions/graphqlApi/types/towingOrder'
import { StateStore } from '../../StoreProvider'
import TowingJobs from './TowingJobs'
import { Loading } from '../../../components/responses'

export type QueryValues = {
  filters: TowingOrderFilters
  status: 'ALL' | 'COMPLETED' | 'OPEN'
  search: string
}

const FilterTowingJobsPage: React.FunctionComponent<InjectedIntlProps> = () => {
  const { state } = useContext(StateStore)

  if (!state.currentUser || !state.settings || !state.userPreferences || !state.userPreferences.filters) {
    return <Loading />
  }

  return (
    <TowingJobs filters={state.userPreferences.filters} settings={state.settings} currentUser={state.currentUser} />
  )
}

export default injectIntl(FilterTowingJobsPage)
