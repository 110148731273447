import React from 'react'
import { FormSubGroup, Label } from '../../layout'
import { SelectContainer, useTowingJobFilterContext } from '../TowingJobFilter'
import { FilterSelect } from '../components/FilterSelect'
import { UserRole } from '../../../common/types'
import { MultiValue, Option } from '../components/MultiValueSelect'

interface OperatorOption {
  label: string
  value: number
}
interface OperatorFilterProps {
  options: OperatorOption[]
}

export const OperatorFilter = ({ options }: OperatorFilterProps) => {
  const { values, setValues, currentUser } = useTowingJobFilterContext()

  const handleOperatorChange = (e: any) => {
    const selectedOperators = e ? e.map((opt: any) => opt.value) : []

    setValues({
      ...values,
      filters: {
        ...values.filters,
        operators: selectedOperators,
      },
    })
  }

  const show = currentUser.role === UserRole.main_user || currentUser.role === UserRole.duty_officer
  return show ? (
    <FormSubGroup>
      <Label>Operaattori</Label>
      <SelectContainer>
        <FilterSelect
          menuPlacement="auto"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          placeholder="Valitse..."
          isMulti
          options={options}
          value={options
            .filter(option => values.filters.operators.includes(option.value))
            .map(option => ({ value: option.value, label: option.label }))}
          onChange={handleOperatorChange}
          components={{ MultiValue, Option }}
          maxToShow={3}
        />
      </SelectContainer>
    </FormSubGroup>
  ) : null
}
