import React, { useContext, useState, useEffect } from 'react'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import { FormSubGroupWrapper, FormSubGroupHeader, FormSubGroupHeading, FormSubGroup } from '../layout'
import { FilterForm } from './FilterForm'
import { StateStore, DispatchStore } from '../../containers/StoreProvider'
import { Preferences } from '../../containers/Parameters/types'
import { setUserPreferences } from '../../containers/StoreProvider/actions'
import { getStoredUserId, storeUserPreferences } from '../../graphql-client/storedState'
import { jobFilterMessages } from './messages'
import { setSuccessNotification, setErrorNotification, dismissNotifications } from '../notification'
import { Loading } from '../responses'
import { GetState } from '../../containers/Parameters/preferences'
import { useMutation } from 'react-apollo'
import { SAVE_PREFERENCES } from './mutation/mutation'
import { SavePreferencesResponse } from './mutation/types'
import { toMutationVariables } from './mutation/toMutationVariables'
import { setErrorNotifications } from '../responses/setErrorNotifications'
import { getStationFilters } from '../../containers/Parameters/station'

const JobViewFiltersIntl: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { state } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)
  const [loading, setLoading] = useState<boolean>(false)
  const { userPreferences, settings } = state
  const { formatMessage } = intl

  const scrollTop = () => {
    const body = document.querySelector('body')
    if (body) {
      body.scrollTo(0, 0)
    }
  }

  const [updateFilters, { loading: mutationLoading }] = useMutation<SavePreferencesResponse>(SAVE_PREFERENCES, {
    onCompleted({ savePreferences }) {
      console.log('preferencesData', savePreferences)
      if (savePreferences.__typename === 'SavePreferencesSuccess') {
        const { id, filters } = savePreferences.preferences
        const savedValues = { id, filters: filters ? JSON.parse(filters) : null }
        const values = getStationFilters(settings, getStoredUserId(), savedValues)

        if (values) {
          storeUserPreferences(values)
          const action = setUserPreferences(values)
          if (action) {
            dispatch(action)
            return setSuccessNotification(
              formatMessage(jobFilterMessages.save_title_success),
              formatMessage(jobFilterMessages.save_message_success)
            )
          }
        }
      } else {
        setErrorNotifications({ data: savePreferences })
      }
    },
    onError(error) {
      console.error(error)
      return setErrorNotification(
        formatMessage(jobFilterMessages.save_title_error),
        formatMessage(jobFilterMessages.save_message_error)
      )
    },
  })

  const saveFilters = async (values: Preferences) => {
    const id = userPreferences && userPreferences.id ? userPreferences.id : null
    values.id = id
    await updateFilters({ variables: toMutationVariables(values) })
  }

  useEffect(() => {
    scrollTop()
    setTimeout(() => {
      dismissNotifications()
    }, 2000)
  }, [userPreferences])

  const filters = GetState.filters(state)
  return (
    <FormSubGroupWrapper>
      <FormSubGroupHeader>
        <FormSubGroupHeading>Työlistauksen oletussuodatus</FormSubGroupHeading>
      </FormSubGroupHeader>
      <FormSubGroup>
        <FilterForm
          filters={filters}
          saveFilters={saveFilters}
          user={state.currentUser}
          loading={loading}
          setLoading={setLoading}
        />
        <Loading loading={loading || mutationLoading} />
      </FormSubGroup>
    </FormSubGroupWrapper>
  )
}

export const JobViewFilters = injectIntl(JobViewFiltersIntl)
