import { useQuery } from '@apollo/react-hooks'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Button } from '../../../components/Button'
import { ButtonColumn, ButtonRow, Column, Page, Row, FlexContainer, Heading3 } from '../../../components/layout'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../components/router'
import { TowingRecordRow } from '../../../components/TowingRecordRow'
import { StateStore } from '../../../containers/StoreProvider'
import { getStationByIdFromSettings } from '../../Parameters/station'
import { getOperatorLabelFromId } from '../../Parameters/user'
import { messages } from '../messages'

import { TOWING_JOBS_SEARCH_QUERY, TowingJobsResponse } from '../TowingJobsQuery'
import { initialValues, TowingJobFilter } from '../../../components/TowingJobFilter/TowingJobFilter'
import { ActionButtons, FilterModal, Search } from '../../../components/TowingJobFilter/components/FilterModal'
import { Loading } from '../../../components/responses'
import Link from '../../../components/Link'

const HeaderContainer = styled(FlexContainer)`
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    justify-content: space-between;
  }
`

const AddButton = styled(Button)`
  max-width: 100%;
`

const CompletedJobsLink = styled(Link)`
  margin-left: 2rem;
`

const CompletedTowingJobsPage: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const [expandedRowId, clickOnRow] = useState<number | null>(null)
  const handleItemClick = (clickedId: number) => {
    clickOnRow(clickedId !== expandedRowId ? clickedId : null)
  }

  const TypedGenericResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingJobsResponse>
  const historyPush = useHistoryPush()

  const { formatMessage } = intl
  const translateMessage = (messageId: string) => formatMessage({ id: messageId })
  const towingJobsQueryResult = useQuery<TowingJobsResponse>(TOWING_JOBS_SEARCH_QUERY, {
    variables: {
      filters: {
        ...initialValues.filters,
        includeTowingOrders: false,
      },
      status: 'COMPLETED',
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  const { state } = useContext(StateStore)
  const currentUser = state.currentUser

  if (!currentUser) {
    return <Loading />
  }

  return (
    <TypedGenericResultHandler queryResult={towingJobsQueryResult} loading unauthorized>
      {responseData => {
        const data = responseData
        return (
          <Page noPadding>
            <Row space="double">
              <Column>
                <HeaderContainer>
                  <Heading3>Valmiit työt</Heading3>
                  <CompletedJobsLink to="/towing-jobs">Työlista</CompletedJobsLink>
                </HeaderContainer>
              </Column>
              <Column>
                <ButtonRow padding="0">
                  <ButtonColumn>
                    <AddButton
                      category="new"
                      label={formatMessage(messages.newTowingOrderButton)}
                      onClick={() => {
                        historyPush('/towing-order/add')
                      }}
                    />
                  </ButtonColumn>
                </ButtonRow>
              </Column>
            </Row>
            <Row space="double">
              <Column>
                <HeaderContainer justifyContent="space-between">
                  <TowingJobFilter
                    show={false}
                    currentUser={currentUser}
                    loading={towingJobsQueryResult.loading}
                    setValues={() => {}}
                    resetToDefaultOptions={() => {}}
                    values={{
                      ...initialValues,
                      filters: {
                        ...initialValues.filters,
                      },
                      status: 'COMPLETED',
                    }}
                  >
                    <FilterModal>
                      <Search />
                      <ActionButtons />
                    </FilterModal>
                  </TowingJobFilter>
                </HeaderContainer>
              </Column>
            </Row>
            {data &&
              data.towingRecords.towingRecords.map(towingRecord => (
                <TowingRecordRow
                  expandedRow={expandedRowId === towingRecord.id}
                  handleItemClick={handleItemClick}
                  towingRecord={towingRecord}
                  translateMessage={translateMessage}
                  key={towingRecord.id}
                  slug={`/towing-record/${towingRecord.id}`}
                  station={getStationByIdFromSettings(towingRecord.stationId, state.settings)}
                  operatorName={getOperatorLabelFromId(
                    towingRecord.operatorId,
                    state.currentUser,
                    state.settings ? state.settings.operators : null
                  )}
                />
              ))}
          </Page>
        )
      }}
    </TypedGenericResultHandler>
  )
}

export default injectIntl(CompletedTowingJobsPage)
