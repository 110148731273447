import React from 'react'
import { FunctionComponent } from 'react'
import { useApolloClient } from 'react-apollo'
import posed, { PoseGroup } from 'react-pose'
import { Redirect, Route, Switch } from 'react-router-dom'

import LoginPage from '../../containers/LoginPage'
import { SearchPage } from '../../containers/SearchPage'
import { Settings } from '../../containers/Settings'
import { ShiftsPage } from '../../containers/Shifts'
import { CompletedTowingJobsPage, FilterTowingJobsPage } from '../../containers/TowingJobsPage'
import { AddTowingOrder } from '../../containers/TowingOrderPage/AddTowingOrder'
import { EditTowingOrder } from '../../containers/TowingOrderPage/EditTowingOrder'
import { ViewTowingOrder } from '../../containers/TowingOrderPage/ViewTowingOrder'
import { AddTowingRecord } from '../../containers/TowingRecordPage/AddTowingRecord'
import { EditTowingRecord } from '../../containers/TowingRecordPage/EditTowingRecord'
import { ViewTowingRecord } from '../../containers/TowingRecordPage/ViewTowingRecord'
import { hasStoredLogin } from '../../graphql-client/storedState'
import { canSendMessageToWebView, sendMessageToWebView } from '../../util/react-native'
import { WebMessageType } from '../../util/react-native/types'
import { NavigationMenu } from '../NavigationMenu'
import { Loading } from '../responses'
import PrivateRoute from './PrivateRoute'
import styled from 'styled-components'
import { useTheme } from '../../containers/ThemeProvider'

interface Props {
  authenticated: boolean
  location: any
}

export const Routes: FunctionComponent<Props> = ({ authenticated, location }) => {
  const RoutesContainer = posed.div({
    enter: { opacity: 1, delay: 100 },
    exit: { opacity: 0 },
  })
  const client = useApolloClient()

  if (!hasStoredLogin() && location.pathname === '/' && canSendMessageToWebView()) {
    sendMessageToWebView({ type: WebMessageType.LOGIN })
    return <Loading loading={true} />
  }

  return (
    <Overlay>
      {authenticated && <NavigationMenu />}
      <PoseGroup>
        <RoutesContainer key={location.pathname}>
          <Switch location={location}>
            <Route path="/login" render={() => (authenticated ? <Redirect to="/towing-jobs" /> : <LoginPage />)} />
            <Route path="/" exact render={() => (authenticated ? <Redirect to="/towing-jobs" /> : <LoginPage />)} />

            <PrivateRoute
              path="/towing-jobs"
              exact
              authenticated={authenticated}
              component={FilterTowingJobsPage}
              client={client}
            />
            {/* <PrivateRoute
              path="/towing-jobs"
              exact
              authenticated={authenticated}
              component={OpenTowingJobsPage}
              client={client}
            /> */}
            <PrivateRoute
              path="/towing-jobs/completed"
              exact
              authenticated={authenticated}
              component={CompletedTowingJobsPage}
              client={client}
            />
            <PrivateRoute path="/settings" exact authenticated={authenticated} component={Settings} client={client} />
            <PrivateRoute
              path="/towing-record/add"
              exact
              authenticated={authenticated}
              component={AddTowingRecord}
              client={client}
            />

            <PrivateRoute
              path="/towing-record/:id/edit"
              exact
              authenticated={authenticated}
              component={EditTowingRecord}
              client={client}
            />
            <PrivateRoute
              path="/towing-record/:id/edit/:scrollTo"
              exact
              authenticated={authenticated}
              component={EditTowingRecord}
              client={client}
            />
            <PrivateRoute
              path="/towing-record/:id/edit/:scrollTo/:paymentType"
              exact
              authenticated={authenticated}
              component={EditTowingRecord}
              client={client}
            />
            <PrivateRoute
              path="/towing-record/:id/:action"
              exact
              authenticated={authenticated}
              component={ViewTowingRecord}
              client={client}
            />
            <PrivateRoute
              path="/towing-record/:id"
              exact
              authenticated={authenticated}
              component={ViewTowingRecord}
              client={client}
            />
            <PrivateRoute
              path="/towing-order/add"
              exact
              authenticated={authenticated}
              component={AddTowingOrder}
              client={client}
            />
            <PrivateRoute
              path="/towing-order/add/:action"
              exact
              authenticated={authenticated}
              component={AddTowingOrder}
              client={client}
            />
            <PrivateRoute
              path="/towing-order/:id"
              exact
              authenticated={authenticated}
              component={ViewTowingOrder}
              client={client}
            />
            <PrivateRoute
              path="/towing-order/:id/edit/:scrollTo"
              authenticated={authenticated}
              component={EditTowingOrder}
              client={client}
            />
            <PrivateRoute
              path="/towing-order/:id/edit"
              authenticated={authenticated}
              component={EditTowingOrder}
              client={client}
            />
            <PrivateRoute path="/search" exact authenticated={authenticated} component={SearchPage} client={client} />
            <PrivateRoute path="/shifts" exact authenticated={authenticated} component={ShiftsPage} client={client} />

            <Redirect from="/" to="/towing-jobs" />
          </Switch>
        </RoutesContainer>
      </PoseGroup>
    </Overlay>
  )
}

//@ important! overflow: auto => to allow scrolling
const StyledOverlay = styled.div<{ visible: boolean }>`
  background-color: ${props => (props.visible ? 'rgba(0, 0, 0, 0.90)' : 'rgba(0, 0, 0, 0);')};
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
`
const Overlay = ({ children }: { children?: React.ReactNode }) => {
  const { currentTheme } = useTheme()
  return <StyledOverlay visible={currentTheme === 'dark'}>{children}</StyledOverlay>
}
