import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  left: -20px;
  top: 5.5%;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
        top: 2.5%;
  }
`

const Circle = styled.label<{ color: string }>`
  height: 12px;
  width: 12px;
  background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
  border-radius: 50%;
  display: inline-block;
`

interface TowingPriorityCircleProps {
  tag: number
}

export const TowingPriorityCircle = ({ tag }: TowingPriorityCircleProps) => {
  return tag ? (
    <Container>
      <Circle color={getColor(tag)} />
    </Container>
  ) : null
}

function getColor(tag: number) {
  if (tag === 3) return 'green700'
  if (tag === 2) return 'yellow700'
  if (tag === 1) return 'red700'

  return ''
}
