import styled, { css } from 'styled-components'

const FormPadding = css`
  padding: 0.5rem 1.5rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0.5rem;
  }
`

export const FormSubGroupHeader = styled.div<FormSubGroupProps>`
  margin: 0 0 1.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'space-between'}
  align-items: center;
  height: 4rem;
  border-bottom: 1px solid ${props => props.theme.colors.grey300};
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.grey100};
  border-radius: ${props => props.theme.radius.medium} ${props => props.theme.radius.medium} 0 0;
  ${FormPadding}
`

export const FormSubGroupHeading = styled.div`
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: ${props => props.theme.fontWeight.bold};
`

interface FormSubGroupProps {
  padding?: string
  margin?: string
  justifyContent?: string
}

export const FormSubGroup = styled.div<FormSubGroupProps>`
  margin: ${props => (props.margin ? props.margin : '0')};
  padding: ${props => props.padding && props.padding};

  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  ${FormPadding}
`

export const FormPadder = styled.div`
  ${FormPadding}
`

export const FormSubGroupWrapper = styled.div<{margin?: string, height?: string, overflow?: string, paddingBottom?: string}>`
  margin: ${props => props.margin ? props.margin : '0 1rem 1.5rem 1rem'}; 
  padding: 0;
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : ''};
  height: ${props => props.height ? props.height : ''};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: ${props => props.theme.radius.medium};
  box-shadow: 0 5px 5px -4px rgba(220, 220, 220, 0.8);
  overflow: ${props => props.overflow ? props.overflow : ''};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin: 0.5rem;
  }
`

export const FormSubGroupsList = styled.div`
  & ${FormSubGroupWrapper}:nth-child(2n) {
  }
`
