import Maybe from 'graphql/tsutils/Maybe'
import React, { createContext, useContext, useState } from 'react'
import { TowingOrderFilters } from '../../functions/graphqlApi/types/towingOrder'
import { ActionButtons, FilterModal, ToggleAll } from './components/FilterModal'
import { JobDayFilter } from './filters/JobDayFilter'
import { JobTypeFilter } from './filters/JobTypeFilter'
import { PriorityFilter } from './filters/PriorityFilter'
import { StationFilter } from './filters/StationFilter'
import { StatusFilter } from './filters/StatusFilter'
import { VisibilityFilter } from './filters/VisibilityFilter'
import { useTowingJobFilters } from './useTowingJobFilters'

import styled from 'styled-components'
import { User } from '../../common/types'

export type TowingJobStatus = 'ALL' | 'COMPLETED' | 'OPEN'

export type QueryValues = {
  filters: TowingOrderFilters
  status: TowingJobStatus
  search: string
}
export const initialValues: QueryValues = {
  filters: {
    includeTowingRecords: true,
    includeTowingOrders: true,
    stations: [],
    labels: [],
    visibility: [],
    operators: [],
    hidePreOrders: false,
    jobDayFilters: [],
    priorityTag: [],
  },
  status: 'OPEN',
  search: '',
  // searchFields: [],
}

interface TowingJobFilterProps {
  show?: boolean
  values: QueryValues
  currentUser: User
  resetToDefaultOptions: () => void
  setValues: (values: QueryValues) => void
  loading: boolean
  isDefaultModified?: boolean
  children?: React.ReactNode
}

interface TowingJobFilterContext {
  values: QueryValues
  initialValues: Readonly<QueryValues>
  currentUser: User
  resetToDefaultOptions: () => void
  setValues: (values: QueryValues) => void
  loading: boolean
  isDefaultModified: boolean
}

const TowingJobFilterContext = createContext<Maybe<TowingJobFilterContext>>(null)

export const useTowingJobFilterContext = (): TowingJobFilterContext => {
  const context = useContext(TowingJobFilterContext)
  if (!context) {
    throw new Error('useTowingJobFilterContext must be used within a TowingJobFilterProvider')
  }
  return context
}

export const TowingJobFilter = ({
  show,
  currentUser,
  values,
  resetToDefaultOptions,
  setValues,
  loading,
  isDefaultModified,
  children,
}: TowingJobFilterProps) => {
  const [initialValues] = useState(values)

  return show ? (
    <TowingJobFilterContext.Provider
      value={{
        initialValues,
        values,
        setValues,
        resetToDefaultOptions,
        loading,
        isDefaultModified: isDefaultModified ? isDefaultModified : false,
        currentUser,
      }}
    >
      {children}
    </TowingJobFilterContext.Provider>
  ) : null
}

export {
  ActionButtons,
  FilterModal,
  JobDayFilter,
  JobTypeFilter,
  PriorityFilter,
  StationFilter,
  StatusFilter,
  ToggleAll,
  useTowingJobFilters,
  VisibilityFilter,
}
export default TowingJobFilter

export const SelectContainer = styled.div`
  margin: 0.25rem 0 0 0;
`
