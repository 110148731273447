import { useMutation } from '@apollo/react-hooks'
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import styled from 'styled-components'

import { Page } from '../../components/layout'
import { setFatalErrorNotification } from '../../components/responses/setErrorNotifications'
import { LOGIN_CLIENTSTATE_MUTATION } from '../../graphql-client/graphql-client'
import { checkToken, login } from '../../util/login'
import Background from './background.png'
import { LoginForm } from './LoginForm'
import { useTheme } from '../ThemeProvider'

const messages = defineMessages({
  login: {
    id: 'containers.login.login',
    defaultMessage: 'login',
  },
  username: {
    id: 'containers.login.username',
    defaultMessage: 'username',
  },
  password: {
    id: 'containers.login.password',
    defaultMessage: 'password',
  },
}) 
const PageWithBackground = styled(Page)<{darkColors?: boolean}>`
  background: ${props => !props.darkColors ? `linear-gradient(rgba(250, 191, 64, 0.5) 0%, rgba(250, 191, 64, 1) 35%, rgba(250, 191, 64, 1) 100%), url(${Background});` :
                                             `linear-gradient(rgb(55 37 0 / 50%) 0%, rgb(43 42 39) 35%, rgba(250,191,64,0.1) 100%), url(${Background});` } 
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  border-top: 0;
`

const LoginPage: FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { formatMessage } = intl
  const { currentTheme } = useTheme()
  const [isLoginFailed, setLoginFailed] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [loginClientState] = useMutation(LOGIN_CLIENTSTATE_MUTATION, {
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  useEffect(() => {
    const doCheckToken = async () => {
      try {
        await checkToken(loginClientState)
        setLoading(false)
      } catch (e) {
        console.error(e)
        setLoading(false)
      }
    }
    doCheckToken()
  }, [loginClientState])

  const loginHandler = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      try {
        setLoginFailed(false)
        await login(username, password, loginClientState)
      } catch (e) {
        console.error(e)
        setLoginFailed(true)
      }
    },
    [loginClientState]
  )

  return (
    <PageWithBackground darkColors={currentTheme === "dark"} color="yellow" justify="center">
      {!loading && (
        <LoginForm
          login={loginHandler}
          loginFailed={isLoginFailed}
          loginLabel={formatMessage(messages.login)}
          passwordPlaceholder={formatMessage(messages.password)}
          usernamePlaceholder={formatMessage(messages.username)}
        />
      )}
    </PageWithBackground>
  )
}

export default injectIntl(LoginPage)
