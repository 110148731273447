import React from 'react'
import { FormSubGroup, Label } from '../../layout'
import { SelectContainer, useTowingJobFilterContext } from '../TowingJobFilter'
import { FilterSelect } from '../components/FilterSelect'
import { MultiValue, Option } from '../components/MultiValueSelect'

interface StationOption {
  label: string
  value: number
}

interface StationOptionsProps {
  options: StationOption[]
}

export const StationFilter = ({ options }: StationOptionsProps) => {
  const { values, setValues } = useTowingJobFilterContext()
  const { stations } = values.filters

  const optionsValues = options

  const handleLabelChange = (selectedOptions: any) => {
    const selectedStations = selectedOptions ? selectedOptions.map((opt: any) => opt.value) : []

    setValues({
      ...values,
      filters: {
        ...values.filters,
        stations: selectedStations,
      },
    })
  }

  return (
    <FormSubGroup>
      <Label htmlFor="filter-multiselect">Asemapaikka</Label>
      <SelectContainer>
        <FilterSelect
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          placeholder="Valitse..."
          options={optionsValues.map(option => ({ value: option.value, label: option.label }))}
          value={optionsValues
            .filter(option => stations.includes(option.value))
            .map(option => ({ value: option.value, label: option.label }))}
          onChange={handleLabelChange}
          components={{ MultiValue, Option }}
          maxToShow={3}
        />
      </SelectContainer>
    </FormSubGroup>
  )
}
