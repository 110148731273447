import React from "react"
import { components, MultiValueProps, OptionProps, OptionTypeBase } from "react-select"
import { FilterCheckBox } from "./FilterCheckBox"
import { theme } from "../../../styled"

interface ExtendedMultiValueProps<OptionType extends OptionTypeBase> extends MultiValueProps<OptionType> {
  maxToShow?: number
}

export const MultiValue = ({ data, getValue, ...props }: ExtendedMultiValueProps<any>) => {
  const maxToShow = props.selectProps.maxToShow ? props.selectProps.maxToShow : 3
  const index = getValue().findIndex((value: any) => value === data)

  const overflow = getValue()
    .slice(index)
    .map((x: any) => x.label)

  return index < maxToShow ? (
    <components.MultiValue getValue={getValue} data={data} {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null
}

export const Option = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <FilterCheckBox checked={props.isSelected} label={props.label} onChange={() => {}} />
    </components.Option>
  )
}

const MoreSelectedBadge = ({ items }: any) => {
  const style = {
    marginLeft: 'auto',
    background: theme.colors.blue200,
    borderRadius: '5px',
    fontFamily: 'Open Sans',
    fontSize: '11px',
    padding: '3px',
    order: 99,
  }

  const title = items.join(', ')
  const length = items.length
  const label = `+ ${length} Muuta valittu`

  return (
    <div style={style} title={title}>
      {label}
    </div>
  )
}

export const customMultiValueStyles = {
    multiValue: (baseStyles: any, state: any) => {
      const { value } = state.data
      let backgroundColor = '#e6e6e6'
  
      if (value === 1) backgroundColor = theme.colors.red700
      else if (value === 2) backgroundColor = theme.colors.yellow700
      else if (value === 3) backgroundColor = theme.colors.green700
  
      return {
        ...baseStyles,
        backgroundColor,
        borderRadius: '5px',
        padding: '2px 5px',
        color: theme.colors.black,
      }
    },
    multiValueLabel: (baseStyles: any, state: any) => ({
      ...baseStyles,
      color: state.data.value > 0 ? theme.colors.white : theme.colors.black,
    }),
    multiValueRemove: (baseStyles: any, _state: any) => ({
      ...baseStyles,
      color: 'white',
      ':hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    }),
  }
  