import React from "react"
import { theme } from "../../../styled"
import { SelectField } from "../../layout/form/style/ReactSelect"

export const FilterSelect = (props: any) => {
    return <SelectField  classNamePrefix={'Select'} styles={customMultiSelectStyles} {...props} />
}

const customMultiSelectStyles = {
  multiValue: (baseStyles: any, _state: any) => {
    let backgroundColor = theme.colors.green700

    return {
      ...baseStyles,
      backgroundColor,
      borderRadius: '5px',
      padding: '2px 5px',
      color: theme.colors.black,
    }
  },
  multiValueLabel: (baseStyles: any, _state: any) => ({
    ...baseStyles,
    color: theme.colors.white,
  }),
  multiValueRemove: (baseStyles: any, _state: any) => ({
    ...baseStyles,
    color: 'white',
    ':hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  }),
}