import React from "react"
import { FormSubGroup, Label } from '../../layout'
import { FilterSelect } from '../components/FilterSelect'
import { SelectContainer, useTowingJobFilterContext } from '../TowingJobFilter'
import { customMultiValueStyles } from '../components/MultiValueSelect'

interface PriorityOption {
  label: string
  value: number
}
interface PriorityOptionsProps {
  options?: PriorityOption[]
}
export const PriorityFilter = ({ options }: PriorityOptionsProps) => {
  const { values, setValues } = useTowingJobFilterContext()

  const optionsValues = options
    ? options
    : [
        { label: 'Ei priorisoitu', value: 0 },
        { label: 'Punainen', value: 1 },
        { label: 'Keltainen', value: 2 },
        { label: 'Vihreä', value: 3 },
      ]

  const handleLabelChange = (selectedOptions: any) => {
    const selectedPriorityTags = selectedOptions ? selectedOptions.map((opt: any) => opt.value) : []

    setValues({
      ...values,
      filters: {
        ...values.filters,
        priorityTag: selectedPriorityTags,
      },
    })
  }

  return (
    <FormSubGroup>
      <Label htmlFor="filter-multiselect">Prioriteetti</Label>
      <SelectContainer>
        <FilterSelect
          isMulti
          menuPlacement="auto"
          placeholder="Valitse..."
          options={optionsValues.map(option => ({ value: option.value, label: option.label }))}
          value={optionsValues
            .filter(option => values.filters.priorityTag.includes(option.value))
            .map(option => ({ value: option.value, label: option.label }))}
          onChange={handleLabelChange}
          styles={customMultiValueStyles}
        />
      </SelectContainer>
    </FormSubGroup>
  )
}