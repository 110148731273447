import { useState } from 'react'
import { Maybe, User, UserRole } from '../../common/types'
import { initialValues, QueryValues } from './TowingJobFilter'

interface TowingJobFilterProps {
  filters: {
    visibility?: string[]
    labels?: string[]
    stations?: number[]
  }
}

const TOWING_JOB_FILTERS_KEY = 'towing_job_filters'

export const useTowingJobFilters = ({
  currentUser,
  options,
  onSetValues,
  onResetValues,
}: {
  currentUser: User
  options?: TowingJobFilterProps
  onSetValues?: (values: QueryValues) => void
  onResetValues?: (values: QueryValues) => void
}) => {
  const localStorageFilters = getLocalStorage(TOWING_JOB_FILTERS_KEY)
  const defaultFilters = getDefaultFilters(options)

  let initialFilters = localStorageFilters ? localStorageFilters.filters : defaultFilters.filters

  if (currentUser.role !== UserRole.main_user && currentUser.role !== UserRole.duty_officer) {
    initialFilters.operators = []
  }

  const [values, setValues] = useState<QueryValues>({
    filters: initialFilters,
    status: localStorageFilters ? localStorageFilters.status : initialValues.status,
    search: '',
  })

  const handleSetValues = (values: QueryValues) => {
    setValues(values)
    // Trigger the optional save callback if provided
    if (onSetValues) {
      onSetValues(values)
    }
  }

  const resetToDefaultOptions = () => {
    setValues(defaultFilters)
    if (onResetValues) {
      onResetValues(defaultFilters)
    }
  }

  return {
    isDefaultModified: JSON.stringify(values) !== JSON.stringify(defaultFilters),
    values,
    setValues: handleSetValues,
    resetToDefaultOptions,
  }
}

export const setLocalStorageTowingJobFilters = (value: any) => {
  localStorage.setItem(TOWING_JOB_FILTERS_KEY, JSON.stringify(value))
}
export const removeLocalStorageTowingJobFilters = () => {
  localStorage.removeItem(TOWING_JOB_FILTERS_KEY)
}

const getLocalStorage = (key: string): Maybe<QueryValues> => {
  const storedValue = localStorage.getItem(key)

  const result = storedValue ? JSON.parse(storedValue) : null

  if (!result) return null

  return validateLocalStorageValues(result)
}

const validateLocalStorageValues = (data: any): QueryValues => {
  return {
    filters: data.filters || initialValues.filters,
    status: data.status || initialValues.status,
    search: typeof data.search === 'string' ? data.search : '',
  }
}

function getDefaultFilters(options?: TowingJobFilterProps) {
  const overrideOptions = options ? options.filters : {}
  const defaultOptions = { ...initialValues, filters: { ...initialValues.filters, ...overrideOptions } }
  return defaultOptions
}
