import { useQuery } from '@apollo/react-hooks'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import styled from 'styled-components'
import { ButtonColumn, ButtonRow, Column, FlexContainer, Heading3, Page, Row } from '../../../components/layout'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { TowingOrderRow } from '../../../components/TowingOrderRow'
import { TowingRecordRow } from '../../../components/TowingRecordRow'
import { getStationByIdFromSettings } from '../../Parameters/station'
import { getOperatorLabelFromId } from '../../Parameters/user'
import { DispatchStore } from '../../StoreProvider'
import { setOpenJobsCount } from '../../StoreProvider/actions'
import { countJobs } from '../../StoreProvider/OpenJobCountWatcher'
import { NoTowingJobs } from '../NoTowingJobs'
import { TOWING_JOBS_SEARCH_QUERY, TowingJobsResponse } from '../TowingJobsQuery'
import { useTowingJobsListChange } from '../towingJobsWatcher'

import { User } from '../../../common/types'
import {
  ActionButtons,
  JobDayFilter,
  JobTypeFilter,
  PriorityFilter,
  StationFilter,
  StatusFilter,
  TowingJobFilter,
  useTowingJobFilters,
  VisibilityFilter,
  FilterModal,
  ToggleAll,
} from '../../../components/TowingJobFilter/TowingJobFilter'
import { JobFilters, Settings } from '../../Parameters/types'
import { Loading } from '../../../components/responses'
import { OperationVariables, QueryResult } from 'react-apollo'
import { OperatorFilter } from '../../../components/TowingJobFilter/filters/OperatorFilter'
import {
  removeLocalStorageTowingJobFilters,
  setLocalStorageTowingJobFilters,
} from '../../../components/TowingJobFilter/useTowingJobFilters'
import { messages } from '../messages'
import { Button } from '../../../components/Button'
import Link from '../../../components/Link'
import { useHistoryPush } from '../../../components/router'

const AddButton = styled(Button)`
  max-width: 100%;
`

const CompletedJobsLink = styled(Link)`
  margin-left: 2rem;
  font-size: 1.2rem;
`

const HeaderContainer = styled(FlexContainer)`
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    justify-content: space-between;
  }
`

interface TowingJobProps {
  currentUser: User
  filters: JobFilters
  settings: Settings
}

const SearchTowingJobsPage: React.FunctionComponent<InjectedIntlProps & TowingJobProps> = ({
  intl,
  currentUser,
  filters,
  settings,
}) => {
  const { dispatch } = useContext(DispatchStore)
  const TypedGenericResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingJobsResponse>
  const translateMessage = (messageId: string) => formatMessage({ id: messageId })
  const { formatMessage } = intl

  const historyPush = useHistoryPush()

  const { values, setValues, isDefaultModified, resetToDefaultOptions } = useTowingJobFilters({
    currentUser: currentUser,
    options: {
      filters: getFiltersFromPreferences(filters),
    },
    onSetValues: setLocalStorageTowingJobFilters,
    onResetValues: removeLocalStorageTowingJobFilters,
  })

  const towingJobsQueryResult = useQuery<TowingJobsResponse>(TOWING_JOBS_SEARCH_QUERY, {
    variables: values,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const [expandedRowId, clickOnRow] = useState<number[]>([])

  const handleExpandRow = (clickedId: number) => {
    if (expandedRowId.includes(clickedId)) {
      clickOnRow(expandedRowId.filter(id => id !== clickedId))
    } else {
      clickOnRow([...expandedRowId, clickedId])
    }
  }

  const handleExpandAll = (isChecked: boolean) => {
    if (isChecked) {
      clickOnRow([...towingOrderIds, ...towingRecordIds])
    } else {
      clickOnRow([])
    }
  }

  const callback = useCallback(() => {
    // tslint:disable-next-line: max-line-length
    // timeout because Innohinaus backend seems to send socket notification before new order is available through REST API
    setTimeout(() => {
      towingJobsQueryResult.refetch()
    }, 5000)
  }, [towingJobsQueryResult])

  useTowingJobsListChange(callback)

  useEffect(() => {
    if (towingJobsQueryResult.data && towingJobsQueryResult.data.towingOrders.towingOrders) {
      const openAndUnassigned = countJobs(towingJobsQueryResult.data.towingOrders.towingOrders)
      dispatch(setOpenJobsCount(openAndUnassigned))
    }
  }, [towingJobsQueryResult.data, dispatch])

  const { towingOrderIds, towingRecordIds } = getTowingJobIds(towingJobsQueryResult)
  const noTowingJobs = towingOrderIds.length === 0 && towingRecordIds.length === 0

  return (
    <TypedGenericResultHandler queryResult={towingJobsQueryResult} loading unauthorized>
      {data => {
        return (
          <Page noPadding>
            <Row space="double">
              <Column>
                <HeaderContainer>
                  <Heading3>Työlista</Heading3>
                  <CompletedJobsLink to="/towing-jobs/completed">Valmiit työt</CompletedJobsLink>
                </HeaderContainer>
              </Column>
              <Column>
                <ButtonRow padding="0">
                  <ButtonColumn>
                    <AddButton
                      category="new"
                      label={formatMessage(messages.newTowingOrderButton)}
                      onClick={() => {
                        historyPush('/towing-order/add')
                      }}
                    />
                  </ButtonColumn>
                  {settings.modules && settings.modules.sos && (
                    <ButtonColumn>
                      <AddButton
                        category="new"
                        label="+ Uusi SOS"
                        onClick={() => {
                          historyPush(`/towing-order/add/sos`)
                        }}
                      />
                    </ButtonColumn>
                  )}
                </ButtonRow>
              </Column>
            </Row>
            <HeaderContainer zIndex="8" justifyContent="space-between">
              <TowingJobFilter
                show
                currentUser={currentUser}
                resetToDefaultOptions={resetToDefaultOptions}
                loading={towingJobsQueryResult.loading}
                isDefaultModified={isDefaultModified}
                values={values}
                setValues={setValues}
              >
                <ToggleAll
                  show={!noTowingJobs}
                  checked={towingOrderIds.length + towingRecordIds.length === expandedRowId.length}
                  onChange={handleExpandAll}
                />
                <FilterModal>
                  <JobDayFilter />
                  <VisibilityFilter />
                  <StatusFilter />
                  <JobTypeFilter options={filters.jobTypes.map(item => ({ label: item.name, value: item.name }))} />
                  <StationFilter options={filters.stations.map(item => ({ label: item.name, value: item.id }))} />
                  <PriorityFilter />
                  <OperatorFilter
                    options={settings.operators.map(operator => ({ label: operator.name, value: operator.id }))}
                  />
                  <ActionButtons isDefaultModified={isDefaultModified} />
                </FilterModal>
              </TowingJobFilter>
            </HeaderContainer>

            {noTowingJobs ? <NoTowingJobs /> : null}
            {towingJobsQueryResult.loading ? <Loading /> : null}

            {data &&
              data.towingOrders.towingOrders.map(towingOrder => (
                <TowingOrderRow
                  expandedRow={expandedRowId.includes(towingOrder.id)}
                  handleItemClick={handleExpandRow}
                  towingOrder={towingOrder}
                  translateMessage={translateMessage}
                  key={towingOrder.id}
                  slug={`/towing-order/${towingOrder.id}`}
                  station={getStationByIdFromSettings(towingOrder.stationId, settings)}
                  operatorName={getOperatorLabelFromId(
                    towingOrder.operatorId,
                    currentUser,
                    settings ? settings.operators : null
                  )}
                />
              ))}

            {data &&
              data.towingRecords.towingRecords.map(towingRecord => (
                <TowingRecordRow
                  expandedRow={expandedRowId.includes(towingRecord.id)}
                  handleItemClick={handleExpandRow}
                  towingRecord={towingRecord}
                  translateMessage={translateMessage}
                  key={towingRecord.id}
                  slug={`/towing-record/${towingRecord.id}`}
                  station={getStationByIdFromSettings(towingRecord.stationId, settings)}
                  operatorName={getOperatorLabelFromId(
                    towingRecord.operatorId,
                    currentUser,
                    settings ? settings.operators : null
                  )}
                />
              ))}
          </Page>
        )
      }}
    </TypedGenericResultHandler>
  )
}

export default injectIntl(SearchTowingJobsPage)

function getFiltersFromPreferences(filters: JobFilters) {
  const filtered = filters.visibility ? Object.entries(filters.visibility).filter(([_, value]) => value) : []
  const visibility = filtered.map(([key]) => key)

  return {
    visibility: visibility,
    labels: filters.jobTypes ? filters.jobTypes.filter(item => item.include).map(item => item.name) : [],
    stations: filters.stations ? filters.stations.filter(item => item.include).map(item => item.id) : [],
  }
}

function getTowingJobIds(query: QueryResult<TowingJobsResponse, OperationVariables>) {
  const towingOrderIds = (query.data && query.data.towingOrders.towingOrders && query.data.towingOrders.towingOrders.map(item => item.id)) || []
  const towingRecordIds = (query.data && query.data.towingRecords.towingRecords && query.data.towingRecords.towingRecords.map(item => item.id)) || []
  return { towingOrderIds, towingRecordIds }
}