import React from 'react'
import { FormSubGroup, Label } from '../../layout'
import { SelectContainer, useTowingJobFilterContext } from '../TowingJobFilter'
import { JobDayFilter as JobDayFilterType } from '../../../functions/graphqlApi/types/towingOrder'
import { FilterCheckBox } from '../components/FilterCheckBox'

export const JobDayFilter = () => {
  const { values, setValues } = useTowingJobFilterContext()
  const { jobDayFilters, hidePreOrders } = values.filters

  const toggleHidePreOrders = () => {
    setValues({
      ...values,
      filters: {
        ...values.filters,
        hidePreOrders: !hidePreOrders,
        jobDayFilters: jobDayFilters.filter(value => value !== 'tomorrow'),
      },
    })
  }

  const handleJobDayFilter = (filter: JobDayFilterType) => {
    const updatedJobDayFilters = jobDayFilters.includes(filter)
      ? jobDayFilters.filter(value => value !== filter)
      : [...jobDayFilters, filter]

    setValues({
      ...values,
      filters: {
        ...values.filters,
        jobDayFilters: updatedJobDayFilters,
      },
    })
  }

  return (
    <FormSubGroup>
      <Label>Työn päivä</Label>
      <SelectContainer>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>
          <FilterCheckBox
            checked={jobDayFilters.includes('yesterday')}
            label={'Eilen'}
            onChange={() => handleJobDayFilter('yesterday')}
          />
          <FilterCheckBox
            checked={jobDayFilters.includes('today')}
            label={'Tänään'}
            onChange={() => handleJobDayFilter('today')}
          />
          <FilterCheckBox
            disabled={values.filters.hidePreOrders}
            checked={jobDayFilters.includes('tomorrow')}
            label={'Huomenna'}
            onChange={() => handleJobDayFilter('tomorrow')}
          />
          <FilterCheckBox checked={hidePreOrders} label={'Piilota ennakot'} onChange={toggleHidePreOrders} />
        </div>
      </SelectContainer>
    </FormSubGroup>
  )
}
