import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../../Button'
import { Icon } from '../../icons/Icon'
import { FormSubGroup, FormSubGroupHeader, Label, Row } from '../../layout'
import { SearchInput } from '../../layout/form/input/SearchInput'
import { useTowingJobFilterContext } from '../TowingJobFilter'

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: end; /* Checkbox on the left, button on the right */
  grid-column: span 1;
`

const FormSubGroupHeading = styled.div`
  white-space: pre-wrap;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: ${props => props.theme.fontWeight.bold};
  text-align: center;
`

const FormSubGroupWrapper = styled.div<{ margin?: string; height?: string; overflow?: string; paddingBottom?: string }>`
  margin: ${props => (props.margin ? props.margin : '0 1rem 1.5rem 1rem')};
  padding: 0;
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '')};
  height: ${props => (props.height ? props.height : '')};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: ${props => props.theme.radius.medium};
  box-shadow: 0 5px 5px -4px rgba(220, 220, 220, 0.8);
  overflow: ${props => (props.overflow ? props.overflow : '')};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin-left: 40px;
  }
`

export const FilterModal = ({ children }: { children?: React.ReactNode }) => {
  const { isDefaultModified } = useTowingJobFilterContext()
  const [show, setShow] = useState(false)

  const closeDrawer = () => setShow(false)
  const openDrawer = () => setShow(true)

  return (
    <StyledContainer>
      <ButtonRow>
        <FilterButton active={isDefaultModified} onClick={openDrawer}>
          <Icon icon={'filter'} color="white" />
          Suodattimet
          {/* {`Suodattimet (${filterCount})`} */}
        </FilterButton>
      </ButtonRow>
      <Backdrop isVisible={show} onClick={closeDrawer} />
      <Drawer isVisible={show}>
        <FormSubGroupWrapper paddingBottom="6rem" overflow="scroll" height="100%" margin="0">
          <FormSubGroupHeader>
            <CloseButton onClick={closeDrawer}>
              <Icon size="small" icon={'close'} />
            </CloseButton>
            <FormSubGroupHeading>Työlistauksen pikasuodatus</FormSubGroupHeading>
          </FormSubGroupHeader>
          {children}
        </FormSubGroupWrapper>
      </Drawer>
    </StyledContainer>
  )
}

export const ActionButtons = ({ isDefaultModified }: any) => {
  const { resetToDefaultOptions, loading } = useTowingJobFilterContext()

  if (loading) {
    return (
      <Row space="double" justify="center" direction="row">
        <Button category={'danger'}>Suodatetaan...</Button>
      </Row>
    )
  }

  return isDefaultModified ? (
    <Row space="double" justify="center" direction="row">
      <Button buttonWidth="100%" onClick={resetToDefaultOptions} category={'clear'}>
        Palauta työlistauksen oletussuodatus
      </Button>
    </Row>
  ) : null
}

const Backdrop = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  z-index: 999;
`
//background: white;
const Drawer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 45%;

  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;

  @media (max-width: ${({ theme }) => theme.screenSize.mobile}) {
    transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(100%)')};
    width: 100%;
  }
`

const CloseButton = styled.button`
  width: auto;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  min-height: 5rem;
  gap: 1rem;
  padding: 0.5rem 1.5rem;
  background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.white)};
  position: sticky;
  top: 0;
  z-index: 999;

  @media (min-width: 1280px) {
    padding: 1.5rem;
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Checkbox on the left, button on the right */
    grid-column: span 1;
  }

  .filter-row {
    display: none;
    flex-wrap: wrap; /* Ensure filters appear in a row */
    gap: 1rem;
    grid-column: span 1;

    &.visible {
      display: flex; /* Show when toggled */
    }
  }
`

const FilterButton = styled.button<{ width?: string; disabled?: boolean; margin?: string; active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  font-size: 1.25rem;
  cursor: pointer;
  color: white;
  border: none;
  padding: 0 1rem;
  border-radius: 10px;
  margin: ${props => (props.margin ? props.margin : '0')}
  width: ${props => (props.width ? props.width : 'auto')}
  background-color: ${props => (props.disabled ? props.theme.colors.grey400 : props.theme.colors.grey400)}
  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.green800};

      transition: background-color 0.2s ease-in-out;
    `}
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    fill: white;
  }
  ${props =>
    !props.disabled &&
    `
     &:hover {
      background-color: #0056b3;
    }
    `}
  
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
        font-size: 1rem;
  }
 
`

export const Search = () => {
  return (
    <FormSubGroup>
      <SearchContainer expanded={true}>
        <SearchInput
          type="text"
          placeholder="Etsi..."
          expanded
          onChange={e => {
            console.log({ search: e.target.value })
          }}
          onBlur={() => true && console.log(false)}
          onFocus={() => console.log(true)}
        />
        <SearchIcon onClick={() => console.log('expand')}>
          <Icon icon="search" size="small" />
        </SearchIcon>
      </SearchContainer>
    </FormSubGroup>
  )
}

const SearchContainer = styled.div<{ expanded?: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: ${props => (props.expanded ? '10px' : '25px')};
  padding: 5px;
  width: ${props => (props.expanded ? '150px' : '46px')};
  transition: width 0.3s ease-in-out;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
`

const SearchIcon = styled.div`
  cursor: pointer;
  padding: 4px;
  font-size: 1.5em;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ToggleAll = ({ show, checked, onChange }: any) => {
  return show ? (
    <div
      style={{
        display: 'flex',
        paddingLeft: '1.5em',
        gap: 2,
        alignContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => onChange(!checked)}
    >
      <Icon size="medium" icon={checked ? 'minus' : 'plus'} />
      <Label style={{ whiteSpace: 'nowrap', margin: 0 }}>{checked ? 'Sulje kaikki' : 'Avaa kaikki'}</Label>
    </div>
  ) : null
}
